"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.store = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const store_1 = require("@/store");
// APP STORE
exports.store = (0, toolkit_1.configureStore)({
    reducer: {
        app: store_1.appState.reducer,
    },
});
