"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAppSelector = exports.useAppDispatch = void 0;
exports.useTranslation = useTranslation;
exports.useIsAdmin = useIsAdmin;
const react_redux_1 = require("react-redux");
const react_1 = require("react");
const AppStore_1 = require("@/store/AppStore");
// Use throughout your app instead of plain `useDispatch` and `useSelector`
const useAppDispatch = () => (0, react_redux_1.useDispatch)();
exports.useAppDispatch = useAppDispatch;
exports.useAppSelector = react_redux_1.useSelector;
function useTranslation() {
    return (0, exports.useAppSelector)(AppStore_1.selectTrans);
}
function useIsAdmin() {
    const permission = (0, exports.useAppSelector)(AppStore_1.selectPermission);
    return (0, react_1.useMemo)(() => permission.includes('admin'), [permission]);
}
