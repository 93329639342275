"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectConnected = exports.selectEndpoint = exports.selectToken = exports.selectPermission = exports.selectIsNew = exports.selectUserId = exports.selectUser = exports.selectUserName = exports.selectTitleG = exports.selectViewG = exports.selectIsDarkModeG = exports.selectTransRaw = exports.selectRawTrans = exports.selectTrans = exports.setUser = exports.setAppStore = exports.setTranslation = exports.setMainView = exports.setDarkModeG = exports.appState = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const react_components_1 = require("@grandlinex/react-components");
const lib_1 = require("@/lib");
const initialAppEnv = {
    isNew: false,
    connected: false,
    userName: '',
    userId: '',
    token: '',
    endpoint: '',
    view: lib_1.Views.HOME,
    title: 'Home',
    darkMode: !react_components_1.LocalStorage.flagLoad('brightTheme'),
    trans: null,
    permission: [],
    user: null,
};
let curTrans = null;
const devTranslation = {};
exports.appState = (0, toolkit_1.createSlice)({
    name: 'app',
    initialState: initialAppEnv,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setIsNew: (state, action) => {
            state.isNew = action.payload;
        },
        setDarkModeG: (state, action) => {
            react_components_1.LocalStorage.flagSave('brightTheme', !action.payload);
            state.darkMode = action.payload;
        },
        setMainView: (state, action) => {
            state.view = action.payload;
        },
        setTranslation: (state, action) => {
            state.trans = action.payload;
            curTrans = new react_components_1.GLang(action.payload);
            curTrans.loadDev(devTranslation);
        },
        setAppStore: (state, action) => {
            state.isNew = action.payload.isNew;
            state.userName = action.payload.userName;
            state.userId = action.payload.userId;
            state.permission = action.payload.permission;
            state.view = action.payload.view;
            state.title = action.payload.title;
            state.title = action.payload.title;
            state.token = action.payload.token;
            state.endpoint = action.payload.endpoint;
            state.darkMode = action.payload.darkMode;
            state.trans = action.payload.trans;
            state.connected = action.payload.connected;
            state.user = action.payload.user;
            curTrans = new react_components_1.GLang(action.payload.trans);
            curTrans.loadDev(devTranslation);
        },
        setUser: (state, action) => {
            state.user = action.payload;
        },
    },
});
_a = exports.appState.actions, exports.setDarkModeG = _a.setDarkModeG, exports.setMainView = _a.setMainView, exports.setTranslation = _a.setTranslation, exports.setAppStore = _a.setAppStore, exports.setUser = _a.setUser;
const selectTrans = (state) => {
    if (!curTrans) {
        curTrans = new react_components_1.GLang(state.app.trans);
        curTrans.loadDev(devTranslation);
    }
    return curTrans;
};
exports.selectTrans = selectTrans;
const selectRawTrans = (state) => state.app.trans;
exports.selectRawTrans = selectRawTrans;
const selectTransRaw = (state) => {
    return state.app.trans;
};
exports.selectTransRaw = selectTransRaw;
const selectIsDarkModeG = (state) => state.app.darkMode;
exports.selectIsDarkModeG = selectIsDarkModeG;
const selectViewG = (state) => state.app.view;
exports.selectViewG = selectViewG;
const selectTitleG = (state) => state.app.title;
exports.selectTitleG = selectTitleG;
const selectUserName = (state) => state.app.userName;
exports.selectUserName = selectUserName;
const selectUser = (state) => state.app.user;
exports.selectUser = selectUser;
const selectUserId = (state) => state.app.userId;
exports.selectUserId = selectUserId;
const selectIsNew = (state) => state.app.isNew;
exports.selectIsNew = selectIsNew;
const selectPermission = (state) => state.app.permission;
exports.selectPermission = selectPermission;
const selectToken = (state) => state.app.token;
exports.selectToken = selectToken;
const selectEndpoint = (state) => state.app.endpoint;
exports.selectEndpoint = selectEndpoint;
const selectConnected = (state) => state.app.connected;
exports.selectConnected = selectConnected;
