"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = useAuthHelper;
exports.useUserImage = useUserImage;
const react_1 = require("react");
const react_components_1 = require("@grandlinex/react-components");
const store_1 = require("@/store");
function useAuthHelper() {
    const api = (0, store_1.useAppSelector)(store_1.selectEndpoint);
    const token = (0, store_1.useAppSelector)(store_1.selectToken);
    return (0, react_1.useCallback)((url, endpoint = false, force = false) => {
        const newUrl = endpoint ? api + url : url;
        const given = new URL(api);
        const target = new URL(newUrl);
        if ((!(0, react_components_1.checkCookie)('glxauth') || force) &&
            given.hostname === target.hostname) {
            target.searchParams.append('glxauth', token.replace(/^Bearer /, ''));
            // console.log('no cookie');
            return target.toString();
        }
        // console.log('cookie or no hotname match');
        return newUrl;
    }, [api, token]);
}
function useUserImage() {
    const auth = useAuthHelper();
    return (0, react_1.useCallback)((id) => {
        return auth(`/api/user/icon/${id}`, true);
    }, [auth]);
}
