"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = ContextMenuComponent;
const react_1 = __importStar(require("react"));
const react_components_1 = require("@grandlinex/react-components");
const react_dom_1 = require("react-dom");
function ContextMenuComponent({ children, options, containerClass, onSelect, }) {
    const ui = (0, react_components_1.useUIContext)();
    const [contextPos, setContextPos] = (0, react_1.useState)(null);
    (0, react_1.useEffect)(() => {
        const fc = () => {
            if (contextPos) {
                setContextPos(null);
                document.removeEventListener('click', fc);
            }
        };
        if (contextPos) {
            document.addEventListener('click', fc);
            return () => {
                document.removeEventListener('click', fc);
            };
        }
        return () => { };
    }, [contextPos]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement("div", { className: containerClass, onContextMenu: (e) => {
                e.preventDefault();
                setContextPos({
                    top: e.clientY,
                    left: e.clientX,
                });
            } }, children),
        contextPos
            ? (0, react_dom_1.createPortal)(react_1.default.createElement(react_components_1.Grid, { flex: true, flexC: true, className: "glx-cm--container", style: {
                    top: contextPos.top,
                    left: contextPos.left,
                }, gap: 2 }, options.map((opt) => {
                switch (opt.type) {
                    case 'divider':
                        return react_1.default.createElement("div", { className: "glx-cm--divider" });
                    case 'label':
                        return react_1.default.createElement("div", { className: "glx-cm--lable" }, opt.label);
                    case 'button':
                    default:
                        if (opt.disabled) {
                            return (react_1.default.createElement(react_components_1.Grid, { flex: true, flexR: true, flexSpaceB: true, className: "glx-cm--disabled", gap: 8 },
                                react_1.default.createElement("span", { className: "glx-cm--label" }, opt.label),
                                react_1.default.createElement("span", { className: "glx-cm--keybind" }, opt.keyBind)));
                        }
                        return (react_1.default.createElement(react_components_1.Grid, { flex: true, flexR: true, flexSpaceB: true, className: "glx-cm--item", onClick: () => {
                                if (onSelect) {
                                    onSelect(opt.key);
                                }
                                setContextPos(null);
                            }, gap: 8 },
                            react_1.default.createElement("span", { className: "glx-cm--label" }, opt.label),
                            react_1.default.createElement("span", { className: "glx-cm--keybind" }, opt.keyBind)));
                }
            })), ui.portalRoot)
            : null));
}
